<template>
  <section>
    <list :type="7"></list>
  </section>
</template>

<script>
import list from '../policy/list'

export default {
  name: 'allPolicy',
  components:{
    list
  },
}
</script>

<style scoped lang="scss">
::v-deep .table-slot-header{
  height: 71px!important;
}
</style>
